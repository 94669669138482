import { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import appRejected from '../../assets/img/rejected-icon.svg'
import { ROUTES } from '../../data/constants'
import cartService from '../../features/cart/services/cartService'
import { revertCartSubmit } from '../../features/cart/redux/cartSlice'
import { toast } from 'react-toastify'
import { ERROR_MESSAGES } from '../../data/constants'

import classes from './BnplOrderDeclined.module.css'

function BnplOrderDeclined() {

  const navigate = useNavigate()
  const { user } = useSelector((state) => state.auth)  
  const dispatch = useDispatch()

  const handleGoBackToCartLinkClick = (e) => {
    e.preventDefault()    
    cartService
      .revertCartSubmit(user.token)
      .then(() => {
        dispatch(revertCartSubmit())
        navigate(ROUTES.CART)
      })
      .catch(() => {        
        toast.error(ERROR_MESSAGES.DEFAULT_ERROR_MESSAGE)
      })
  }

  return (
    <Fragment>
      <Helmet>
          <title>KREDU - Der Antrag wurde abgelehnt</title>
          <meta name="description" content="KREDU - Der Antrag wurde abgelehnt" />
          <meta name="robots" content="noindex,nofollow" /> 
        </Helmet>
      <div className='vh-100 pt-lg-5 text-center container'>
        <div className='container-heigh text-center row'>
          <div className='text-center mx-auto col-12 col-sm-10 col-md-6'>
            <div className='text-center box-width'>
              <img src={appRejected} alt='appRejected' className='my-5'></img>
              <h2 className='fw-bold mb-4'>
                Dein Antrag auf Kauf auf Rechnung wurde abgelehnt
              </h2>
              <p className='px-xl-5 px-sm-3'>
              Leider können wir den Kauf auf Rechnung nicht anbieten.
                <br />
                <br />
                Du hast aber die Möglichkeit, deine Wunschprodukte auch mit anderen Zahlungsmethoden wie Klarna oder PayPal zu bezahlen.
              </p>
              <Link
                to={ROUTES.CART}
                onClick={handleGoBackToCartLinkClick}
                className={`${classes.fontBlue} text-uppercase`}
              >
                zurück zum Warenkorb
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default BnplOrderDeclined
