import { useState, useEffect, Fragment, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { Link, useNavigate } from 'react-router-dom'
import CartItem from '../cart/components/CartItem'
import CartItemOutOfStock from '../cart/components/CartItemOutOfStock'
import LoadingSpinner from '../../components/LoadingSpinner'
import FormError from '../../components/FormError'
import CartError from '../cart/components/CartError'
import FriendlyInfoBox from '../../components/FriendlyInfoBox'
import userService from '../../services/userService'
import cartService from '../cart/services/cartService'
import { clearCart, revertCartSubmit } from '../cart/redux/cartSlice'
import { toast } from 'react-toastify'
import {
  reset,
  loadProductDataOrderLimitDiscountAndOpenOrdersAmount,
} from '../cart/redux/cartSlice'
import { signOut } from '../auth/authSlice'

import { MDBBtn, MDBCard, MDBCheckbox, MDBSpinner } from 'mdb-react-ui-kit'
import { ERROR_MESSAGES, PAYMENT_METHODS, ROUTES } from '../../data/constants'

import classes from './assets/css/Checkout.module.css'

import back from '../../assets/img/arrow-back-blue.svg'

const debounce = (func, wait) => {
  let timeout
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

const getPaymentMethodText = (paymentMethod) => {
  switch (paymentMethod) {
    case PAYMENT_METHODS.KREDU_BNPL:
      return 'Kauf auf Rechnung (30 Tage)'
    case PAYMENT_METHODS.STRIPE_KLARNA:
      return 'Klarna'
    case PAYMENT_METHODS.STRIPE_PAYPAL:
      return 'PayPal'
    case PAYMENT_METHODS.STRIPE_CARDS:
      return 'Kreditkarte'
    case PAYMENT_METHODS.STRIPE_APPLE_PAY:
      return 'Apple Pay'
    case PAYMENT_METHODS.STRIPE_GOOGLE_PAY:
      return 'Google Pay'
    case PAYMENT_METHODS.STRIPE_REVOLUT_PAY:
      return 'Revolut Pay'
    default:
      return 'Unbekannt'
  }
}

function Checkout() {
  const [userData, setUserData] = useState('')
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isUserDataLoading, setIsUserDataLoading] = useState(false)
  const [isFormSubmitProcessing, setIsFormSubmitProcessing] = useState(false)
  const [isFormSubmitError, setIsFormSubmitError] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { user } = useSelector((state) => state.auth)

  const {
    isLoading,
    message,
    cartItems,
    serviceFee,
    serviceFeeVat,
    totalAmount,
    paymentMethod,
    kredupayDiscount,
    kredupayDiscountAmount,
    hasOpenPaysLegacyInvoices,
    hasOpenKredupayInvoices,
    hasOverdueInvoices,
    paysafecardLimit,
    paysafecardAvailableOrderLimit,
    isOpenKreduBnplOrdersLimitExceeded,
    kreduBnplAvailableOrderLimit,
    isPaysafecardLimitExceeded,
    isOpenKlarnaOrdersLimitExceeded,
    klarnaAvailableOrderLimit,
    isOpenPayPalOrdersLimitExceeded,
    payPalAvailableOrderLimit,
  } = useSelector((state) => state.cart)

  useEffect(() => {
    userService
      .getUserData(user.token)
      .then((data) => {
        setIsUserDataLoading(true)
        setUserData(data)
      })
      .then(() => {
        setIsUserDataLoading(false)
      })
      .catch((error) => {
        setIsError(true)
      })

    dispatch(reset())
    dispatch(loadProductDataOrderLimitDiscountAndOpenOrdersAmount())
      .then(() => {
        if (user && user.token && message === '401') {
          dispatch(signOut())
        }
      })
      .catch((error) => {
        setIsError(true)
      })
  }, [dispatch])

  const handleGoBackToCartLinkClick = (e) => {
    e.preventDefault()
    setIsUserDataLoading(true)
    cartService
      .revertCartSubmit(user.token)
      .then(() => {
        dispatch(revertCartSubmit())
        navigate(ROUTES.CART)
      })
      .catch(() => {
        setIsUserDataLoading(false)
        toast.error(ERROR_MESSAGES.DEFAULT_ERROR_MESSAGE)
      })
  }

  const handleFormSubmit = async (e) => {
    if (isFormSubmitProcessing) return
    e.preventDefault()
    setIsFormSubmitProcessing(true)
    setIsFormSubmitError(false)

    await debounceCheckout()
  }

  const checkoutCart = async () => {
    try {
      const result = await cartService.checkoutCart(
        user.token,
        paymentMethod,
        cartItems
      )

      if (result === 'USER_LOCKED') {
        setIsFormSubmitError(true)
        setErrorMessage(
          'Dein Konto ist gesperrt. Bitte kontaktiere unseren Support für weitere Informationen.'
        )
        setIsFormSubmitProcessing(false)
      } else if (result === 'DECLINED') {
        setIsFormSubmitError(true)
        setErrorMessage(
          'Es kann sein, dass du ein doppeltes Konto in unserem System hast oder einige der internen Regeln nicht erfüllt sind. Bitte kontaktiere unseren Support für weitere Informationen.'
        )
        setIsFormSubmitProcessing(false)
      } else if (result === 'CREDIT_CHECK_PENDING') {
        navigate(ROUTES.ORDER_PENDING)
      } else if (result === 'CREDIT_CHECK_DECLINED') {
        navigate(ROUTES.BNPL_ORDER_DECLINED)
      } else if (result === 'OPEN_INVOICES') {
        setIsFormSubmitError(true)
        setErrorMessage(
          'Du kannst aufgrund unbezahlter Rechnungen keine weiteren Einkäufe tätigen. Bitte bezahle die fälligen Rechnungen, um wieder einkaufen zu können.'
        )
        setIsFormSubmitProcessing(false)
      } else if (result === 'CART_INVALID') {
        setIsFormSubmitError(true)
        setErrorMessage(
          'Es gibt ein Problem mit deinem Warenkorb. Bitte entferne alle Artikel und füge sie erneut hinzu.'
        )
        setIsFormSubmitProcessing(false)
      } else if (result === 'PRODUCT_OUT_OF_STOCK') {
        setIsFormSubmitError(true)
        setErrorMessage(
          'Eines der Produkte im Warenkorb ist nicht mehr vorrätig. Gehe zurück zum Warenkorb, um die Artikel zu aktualisieren.'
        )
        setIsFormSubmitProcessing(false)
      } else if (result === 'INSUFFICIENT_QUANTITY') {
        setIsFormSubmitError(true)
        setErrorMessage(
          'Leider möchtest du mehr Artikel bestellen, als wir derzeit auf Lager haben. Reduziere die Anzahl der Artikel im Warenkorb oder kontaktiere unseren Support für weitere Details.'
        )
        setIsFormSubmitProcessing(false)
      } else if (result === 'REQUIRED_DOCUMENTS') {
        navigate(
          ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_ADDITIONAL_DOCUMENTS
        )
      } else {
        if (paymentMethod === 'KREDU_BNPL') {
          dispatch(clearCart())
          navigate(ROUTES.ORDER_SUCCESSFUL)
        } else {
          window.location.replace(result)
        }
      }
    } catch (error) {
      setErrorMessage(ERROR_MESSAGES.DEFAULT_ERROR_MESSAGE)
      setIsFormSubmitError(true)
      setIsFormSubmitProcessing(false)
    }
  }

  const debounceCheckout = useCallback(
    debounce(async () => await checkoutCart(), 2000),
    []
  )

  const hasOpenInvoices =
    hasOpenPaysLegacyInvoices || hasOpenKredupayInvoices || hasOverdueInvoices

  if (isUserDataLoading || isLoading) {
    return (
      <div
        className={`${classes.container} vh-100 pb-5 pt-5 pt-lg-3 mt-5 container`}
      >
        <div className={`mx-auto ${classes.spinnerContainer}`}>
          <LoadingSpinner />
        </div>
      </div>
    )
  }

  if (isError) {
    return (
      <div
        className={`${classes.container} pb-5 text-center pt-5 pt-lg-3 mt-5 container`}
      >
        <CartError />
      </div>
    )
  }

  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Kasse</title>
        <meta name='description' content='KREDU - Kasse' />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      <div className={`${classes.checkout} container`}>
        <h2 className='fw-bold'>Kasse</h2>
        {totalAmount <= 0 && cartItems.every((c) => !c.isInStock) && (
          <div className={classes.infoBox}>
            <FriendlyInfoBox>
              <div className='p-0'>
                Dein Warenkorb enthält nur Artikel, die derzeit nicht verfügbar
                sind. Gehe zurück zu unserer Produktliste, um verfügbare Artikel
                in den Warenkorb zu legen. <br />{' '}
                <Link
                  to='/alle-produkte'
                  className={`${classes.fontBlue} fw-bold text-decoration-underline`}
                >
                  {' '}
                  Zu allen Produkten
                </Link>
              </div>
            </FriendlyInfoBox>
          </div>
        )}
        <div className='row'>
          <Link
            to={ROUTES.CART}
            onClick={handleGoBackToCartLinkClick}
            className={`${classes.link} d-flex mb-4  mt-3 mt-md-4`}
          >
            <img src={back} alt='back' />
            <span className='font-blue ms-2 fw-bold'>Zurück zum Warenkorb</span>
          </Link>
        </div>
        <div className='row'>
          <MDBCard className='p-3 p-sm-4 mb-3 mb-md-4'>
            <h4 className='fw-bold'>Rechnungsdaten</h4>
            <p className='text-muted mb-2'>
              {userData.firstName + ' ' + userData.lastName}
            </p>
            <p className='text-muted mb-2'>
              {userData.streetName} {userData.houseNumber},{' '}
              {userData.postalCode}, {userData.city}
            </p>
            <p className='text-muted mb-2'>
              {userData.email}, {userData.mobilePhoneNumber}
            </p>
            {/* <Link to='' className={`${classes.link} text-decoration-underline`}>
            bearbeiten
          </Link> */}
          </MDBCard>
        </div>
        <div className='row'>
          <MDBCard className='p-3 p-sm-4 mb-3 mb-md-4'>
            <h4 className='fw-bold'>Lieferung</h4>
            <p className='text-muted mb-2'>
              Deine Gutscheine werden dir per E-Mail zugesendet oder in deinem
              Konto sichtbar.
            </p>
          </MDBCard>
        </div>
        <div className='row pb-3'>
          <MDBCard className='p-3 p-sm-4 mb-3 mb-md-4'>
            <h4 className='fw-bold'>Zahlung</h4>
            <p className='text-muted mb-2'>
              {getPaymentMethodText(paymentMethod)}
            </p>
            <Link
              to={ROUTES.CART}
              onClick={handleGoBackToCartLinkClick}
              className={`${classes.link} text-decoration-underline`}
            >
              bearbeiten
            </Link>
          </MDBCard>
        </div>
        <div className={`${classes.card} row mb-2`}>
          <div className='col-8 col-lg-5  col-xl-3'>
            <p className='text-muted m-0'>Produkt</p>
          </div>
          <div className='col-2 d-none d-xl-flex justify-content-center'>
            <p className='text-muted m-0'>Preis</p>
          </div>
          <div className='col-3 col-lg-3 d-none d-lg-flex justify-content-center '>
            <p className='text-muted m-0'>Menge</p>
          </div>
          <div className='col-3  col-lg-3 d-flex justify-content-center'>
            <p className='text-muted m-0'>Gesamtpreis</p>
          </div>
        </div>
        <div className='row'>
          {cartItems.map((cartItem) => {
            return cartItem.isInStock === true ? (
              <CartItem
                key={cartItem.productId}
                productId={cartItem.productId}
                productName={cartItem.name}
                quantity={cartItem.quantity}
                price={cartItem.price}
                slug={cartItem.slug}
                mode='checkout'
              />
            ) : (
              <CartItemOutOfStock
                key={cartItem.productId}
                productId={cartItem.productId}
                productName={cartItem.name}
                slug={cartItem.slug}
                mode='checkout'
              />
            )
          })}
        </div>
        <div className='row'>
          <MDBCard className={`${classes.shoppingCart} mb-3 p-md-3 p-2`}>
            <div className='row'>
              <div className='col-8'>
                <p className='fw-bold mb-0 mb-lg-1'>Servicegebühr</p>
              </div>
              <div className='col-3 d-flex align-items-center justify-content-md-center justify-content-end pe-lg-0'>
                <p className='fw-bold pe-2 pe-md-0 ps-md-3 mb-1'>
                  <span className={classes.small}>
                    {(kredupayDiscountAmount
                      ? serviceFee + kredupayDiscountAmount
                      : serviceFee
                    )
                      .toFixed(2)
                      .replace('.', ',')}
                  </span>
                  &nbsp;€
                </p>
              </div>
              <div>
                <p className='col text-muted mb-1 d-block d-md-none'>
                  Servicegebühr für die Bearbeitung des Verkaufs von Gutscheinen
                </p>
              </div>
            </div>
          </MDBCard>
        </div>
        {kredupayDiscount > 0 && (
          <div className='row'>
            <MDBCard className='shoppingCart mb-3 p-md-3 p-2'>
              <div className='row'>
                <div className='col-8'>
                  <p className='fw-bold mb-1 mb-lg-1'>Kundenrabatt</p>
                  <p className='text-muted d-none mb-0 d-md-block'>
                    Rabatt durch aktive Jahreskartengebühr im Kredupay
                  </p>
                </div>
                <div className='col-3 d-flex align-items-center justify-content-md-center justify-content-end pe-lg-0'>
                  <p className='fw-bold pe-3 pe-md-0 ps-md-3 mb-1'>
                    <span className={classes.small}>
                      -{kredupayDiscountAmount.toFixed(2).replace('.', ',')}
                    </span>
                    €
                  </p>
                </div>
                <div className='col'>
                  <p className='text-muted d-block mb-1 d-md-none'>
                    Rabatt durch aktive Jahreskartengebühr im Kredupay
                  </p>
                </div>
              </div>
            </MDBCard>
          </div>
        )}
        <div className='row'>
          <div className='col-md-6 offset-md-6'>
            <p className='mt-0 mb-0 text-end'>
              Gesamtsumme{' '}
              <span className={`${classes.total} ms-2`}>
                {totalAmount.toFixed(2).replace('.', ',')}
              </span>
              €{' '}
            </p>
            <p className={`${classes.vatBox} mt-0 mb-2 text-end`}>
              inkl. 19 % MwSt.{' '}
              <span className='ms-2'>
                {serviceFeeVat.toFixed(2).replace('.', ',')}
              </span>{' '}
              €{' '}
            </p>
            <form
              onSubmit={handleFormSubmit}
              className={`${classes.form} text-end`}
            >
              <MDBCheckbox
                required
                name='checkNoLabel'
                value=''
                id='newsletterAgreement'
                className={classes.check}
                label={
                  <span className={`${classes.checkbox} fw-normal mb-1 p-0`}>
                    Ich stimme den{' '}
                    <a
                      href='../docs/KREDU_AGB_02_2023.pdf'
                      className={classes.fontBlue}
                    >
                      {' '}
                      AGB
                    </a>{' '}
                    und{' '}
                    <a
                      href='../docs/KREDU_Datenschutzerklaerung.pdf'
                      className={classes.fontBlue}
                    >
                      Datenschutzbestimmungen
                    </a>{' '}
                    zu.
                  </span>
                }
              />
              {isFormSubmitError && (
                <div className='mt-3'>
                  <FormError errors={[errorMessage]} />
                </div>
              )}
              {hasOpenInvoices && (
                <div className='mt-3'>
                  <FormError
                    errors={[
                      'Du kannst aufgrund unbezahlter Rechnungen keine weiteren Einkäufe tätigen. Bitte bezahle die fälligen Rechnungen, um wieder einkaufen zu können.',
                    ]}
                  />
                </div>
              )}
              {paymentMethod === PAYMENT_METHODS.KREDU_BNPL &&
                isOpenKreduBnplOrdersLimitExceeded &&
                !hasOpenInvoices && (
                  <FormError
                    errors={[
                      `Du hast dein maximales Limit für diese Zahlungsmethode
                    erreicht. Dein Limit beträgt derzeit <b>${kreduBnplAvailableOrderLimit
                      .toFixed(2)
                      .replace(
                        '.',
                        ','
                      )} €</b> und wird nach mehreren erfolgreichen Bestellungen erhöht.`,
                    ]}
                  />
                )}
              {paymentMethod === PAYMENT_METHODS.KREDU_BNPL &&
                isPaysafecardLimitExceeded &&
                !isOpenKreduBnplOrdersLimitExceeded &&
                !hasOpenInvoices && (
                  <FormError
                    errors={[
                      `Aufgrund der hohen Nachfrage ist es vorübergehend nur möglich, Paysafecards im Wert von maximal ${paysafecardLimit
                        .toFixed(2)
                        .replace(
                          '.',
                          ','
                        )} € auf Rechnung zu bestellen. Im Moment hast du 
                    noch ${paysafecardAvailableOrderLimit
                      .toFixed(2)
                      .replace('.', ',')} € deines Limits übrig. Bitte 
                    reduziere die Anzahl der Paysafecards in deinem Warenkorb, bezahle
                    offene Rechnungen oder verwende eine andere Zahlungsmethode mit
                    höheren Limits, z. B. Klarna oder PayPal, um mehr bestellen zu können.`,
                    ]}
                  />
                )}
              {paymentMethod === PAYMENT_METHODS.STRIPE_KLARNA &&
                isOpenKlarnaOrdersLimitExceeded &&
                !hasOpenInvoices && (
                  <FormError
                    errors={[
                      `Dein aktueller Verfügungsrahmen bei Klarna beträgt <b>${klarnaAvailableOrderLimit
                        .toFixed(2)
                        .replace(
                          '.',
                          ','
                        )} €</b>. Sobald wir die Zahlung für deine vorherigen Einkäufe von Klarna erhalten haben (in der Regel innerhalb von 3 Werktagen), steht dir wieder mehr Geld für deine Einkäufe zur Verfügung.`,
                    ]}
                  />
                )}
              {paymentMethod === PAYMENT_METHODS.STRIPE_PAYPAL &&
                isOpenPayPalOrdersLimitExceeded &&
                !hasOpenInvoices && (
                  <FormError
                    errors={[
                      `Dein aktueller Verfügungsrahmen bei PayPal beträgt <b>${payPalAvailableOrderLimit
                        .toFixed(2)
                        .replace(
                          '.',
                          ','
                        )} €</b>. Sobald wir die Zahlung für deine vorherigen Einkäufe von PayPal erhalten haben (in der Regel innerhalb von 3 Werktagen), steht dir wieder mehr Geld für deine Einkäufe zur Verfügung.`,
                    ]}
                  />
                )}
              <MDBBtn
                type='submit'
                className={`${
                  classes.btnYellow
                } btn-yellow btn mx-auto text-uppercase mt-2 ${
                  (totalAmount <= 0 && cartItems.every((c) => !c.isInStock)) ||
                  hasOpenInvoices ||
                  (paymentMethod === PAYMENT_METHODS.KREDU_BNPL &&
                    isPaysafecardLimitExceeded) ||
                  (paymentMethod === PAYMENT_METHODS.KREDU_BNPL &&
                    isOpenKreduBnplOrdersLimitExceeded) ||
                  (paymentMethod === PAYMENT_METHODS.STRIPE_KLARNA &&
                    isOpenKlarnaOrdersLimitExceeded) ||
                  (paymentMethod === PAYMENT_METHODS.STRIPE_PAYPAL &&
                    isOpenPayPalOrdersLimitExceeded)
                    ? 'disabled'
                    : ''
                }`}
              >
                {isFormSubmitProcessing && (
                  <MDBSpinner
                    size='sm'
                    role='status'
                    tag='span'
                    className={`me-2`}
                  />
                )}
                Jetzt kaufen
              </MDBBtn>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Checkout
