import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import cartService from '../services/cartService'
import { revertCartSubmit } from '../redux/cartSlice'
import {
  MDBCard,
  MDBBtn,
  MDBRadio,
  MDBSpinner,
  MDBBadge,
  MDBTooltip,
} from 'mdb-react-ui-kit'
import CartItem from './CartItem'
import CartItemOutOfStock from './CartItemOutOfStock'
import CartError from './CartError'
import {
  reset,
  setPaymentMethodInCart,
  loadProductDataOrderLimitDiscountAndOpenOrdersAmount,
  submitCart,
} from '../redux/cartSlice'
import LoadingSpinner from '../../../components/LoadingSpinner'
import FormError from '../../../components/FormError'
import FriendlyInfoBox from '../../../components/FriendlyInfoBox'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons'

import klarna from '../assets/img/Klarna.svg'
import cardPay from '../assets/img/card-payment-icon.svg'
import applePay from '../assets/img/apple-pay-icon.svg'
import googlePay from '../assets/img/google-payment-icon.svg'
import revolutPay from '../assets/img/revolut-payment-icon.svg'
import paypal from '../assets/img/paypal-logo.svg'
import kredu from '../assets/img/Kredu-logo.svg'

import classes from '../assets/css/Cart.module.css'
import { ROUTES, PAYMENT_METHODS } from '../../../data/constants'
import { signOut } from '../../auth/authSlice'

function Cart() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    isLoading,
    isSubmitLoading,
    isCartSubmitted,
    isError,
    message,
    isSubmitError,
    cartItems,
    serviceFee,
    serviceFeeVat,
    totalAmount,
    paymentMethod,
    kredupayDiscount,
    kredupayDiscountAmount,
    hasOpenPaysLegacyInvoices,
    hasOpenKredupayInvoices,
    hasOverdueInvoices,
    isOpenKreduBnplOrdersLimitExceeded,
    kreduBnplAvailableOrderLimit,
    paysafecardLimit,
    paysafecardAvailableOrderLimit,
    isPaysafecardLimitExceeded,
    isOpenKlarnaOrdersLimitExceeded,
    klarnaAvailableOrderLimit,
    isOpenPayPalOrdersLimitExceeded,
    payPalAvailableOrderLimit,
    isOpenStripeCardsOrdersLimitExceeded,
    stripeCardsAvailableOrderLimit,
    isOpenStripeApplePayOrdersLimitExceeded,
    stripeApplePayAvailableOrderLimit,
    isOpenStripeGooglePayOrdersLimitExceeded,
    stripeGooglePayAvailableOrderLimit,
    isOpenStripeRevolutPayOrdersLimitExceeded,
    stripeRevolutPayAvailableOrderLimit,
  } = useSelector((state) => state.cart)

  const { user } = useSelector((state) => state.auth)

  // teporary solution for payment method disabled
  const [isPaymentMethodDisabled, setIsPaymentMethodDisabled] = useState(false)

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState(paymentMethod)

  useEffect(() => {
    dispatch(reset())
    dispatch(loadProductDataOrderLimitDiscountAndOpenOrdersAmount()).then(
      () => {
        if (user && user.token) {
          dispatch(revertCartSubmit())
          if (message === '401') {
            dispatch(signOut())
          } else {
            cartService.revertCartSubmit(user.token)
          }
        }
      }
    )
  }, [])

  useEffect(() => {
    if (isCartSubmitted) {
      if (!isSubmitError) {
        if (user) {
          navigate(ROUTES.CHECKOUT)
        } else {
          navigate(ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_EMAIL)
        }
      }
    }
  }, [isCartSubmitted])

  const isPaymentMethodRadioChecked = (value) => {
    return selectedPaymentMethod === value
  }

  const handlePaymentMethodChange = (e) => {
    setSelectedPaymentMethod(e.currentTarget.value)
    dispatch(setPaymentMethodInCart(e.currentTarget.value))
  }

  const handleCartSubmit = async (e) => {
    dispatch(submitCart())
  }

  const isAndroid = () => {
    return true
  }

  const isApple = () => {
    return /Mac|iPhone|iPad|iPod/i.test(navigator.userAgent)
  }

  const hasOpenInvoices =
    hasOpenPaysLegacyInvoices || hasOpenKredupayInvoices || hasOverdueInvoices

  if (isLoading) {
    return (
      <div
        className={`${classes.container} vh-100 pb-5 pt-5 pt-lg-3 mt-5 container`}
      >
        <div className={`mx-auto ${classes.spinnerContainer}`}>
          <LoadingSpinner />
        </div>
      </div>
    )
  }

  if (isError) {
    return (
      <div
        className={`${classes.container} pb-5 text-center pt-5 pt-lg-3 mt-5 container`}
      >
        <CartError />
      </div>
    )
  }

  return (
    <div className='container h-100 pb-5 pt-4 pt-lg-3 mt-5'>
      <h2 className='mb-lg-4 pt-3 pb-2 pt-lg-5 header-top fw-bold'>
        Dein Warenkorb
      </h2>
      {totalAmount <= 0 && cartItems.every((c) => !c.isInStock) && (
        <div className={classes.infoBox}>
          <FriendlyInfoBox>
            <div>
              Dein Warenkorb enthält nur Artikel, die derzeit nicht verfügbar
              sind. Gehe zurück zu unserer Produktliste, um verfügbare Artikel
              in den Warenkorb zu legen. <br />{' '}
              <Link
                to='/alle-produkte'
                className={`${classes.fontBlue}  fw-bold text-decoration-underline`}
              >
                {' '}
                Zu allen Produkten
              </Link>
            </div>
          </FriendlyInfoBox>
        </div>
      )}
      <div className='row d-none d-lg-flex'>
        <div className='col-12 col-lg-5 col-xl-3'>
          <p className='ps-2 text-muted'>Produkt</p>
        </div>
        <div className='col-2 d-none text-center d-xl-block'>
          <p className='ps-2 text-muted'>Preis</p>
        </div>
        <div className='col-12 col-lg-3'>
          <p className='text-center text-muted'>Menge</p>
        </div>
        <div className='col-3'>
          <p className='text-center text-muted'>Gesamtpreis</p>
        </div>
        <div className='col-1'></div>
      </div>

      {cartItems.map((cartItem) => {
        return cartItem.isInStock === true ? (
          <CartItem
            key={cartItem.productId}
            productId={cartItem.productId}
            productName={cartItem.name}
            quantity={cartItem.quantity}
            price={cartItem.price}
            slug={cartItem.slug}
          />
        ) : (
          <CartItemOutOfStock
            key={cartItem.productId}
            productId={cartItem.productId}
            productName={cartItem.name}
            slug={cartItem.slug}
          />
        )
      })}

      <MDBCard className={`${classes.shoppingCart} mb-3 p-md-3 p-2`}>
        <div className='row'>
          <div className='col-8'>
            <p className='fw-bold mb-0 mb-lg-1'>Servicegebühr</p>
          </div>
          <div className='col-3 d-flex align-items-center justify-content-md-center justify-content-end pe-lg-0'>
            <p className='fw-bold pe-2 pe-md-0 ps-md-3 mb-1'>
              <span className={classes.small}>
                {(kredupayDiscountAmount
                  ? serviceFee + kredupayDiscountAmount
                  : serviceFee
                )
                  .toFixed(2)
                  .replace('.', ',')}
              </span>
              &nbsp;€
            </p>
          </div>
          <div>
            <p className='col text-muted mb-1 d-block d-md-none'>
              Servicegebühr für die Bearbeitung des Verkaufs von Gutscheinen
            </p>
          </div>
        </div>
      </MDBCard>
      {kredupayDiscount > 0 && (
        <MDBCard className='shoppingCart mb-3 p-md-3 p-2'>
          <div className='row'>
            <div className='col-8'>
              <p className='fw-bold mb-1 mb-lg-1'>Kundenrabatt</p>
              <p className='text-muted d-none mb-0 d-md-block'>
                Rabatt durch aktive Jahreskartengebühr im Kredupay
              </p>
            </div>
            <div className='col-3 d-flex align-items-center justify-content-md-center justify-content-end pe-lg-0'>
              <p className='fw-bold pe-2 pe-md-0 ps-md-3 mb-1'>
                <span className={classes.small}>
                  -
                  {kredupayDiscountAmount
                    ? kredupayDiscountAmount.toFixed(2).replace('.', ',')
                    : 0}
                </span>
                €
              </p>
            </div>
            <div className='col'>
              <p className='text-muted d-block mb-1 d-md-none'>
                Rabatt durch aktive Jahreskartengebühr im Kredupay
              </p>
            </div>
          </div>
        </MDBCard>
      )}
      <div className='row shopping-cart-total-loggedIn'>
        <div className='col-12 d-flex justify-content-end'>
          <div className='d-flex mb-0'>
            <p className='me-3 mb-0 mt-1'>Gesamtsumme</p>
            <h3 className='d-inline-block me-1'>
              <span className='fw-bold'>
                {totalAmount.toFixed(2).replace('.', ',')}
              </span>
            </h3>
            €
          </div>
        </div>
        <p className={`${classes.vatBox} mt-0 mb-2 text-end`}>
          inkl. 19 % MwSt.{' '}
          <span className='ms-2'>
            {serviceFeeVat.toFixed(2).replace('.', ',')}&nbsp;
          </span>
          €{' '}
        </p>
        <div className='col-12 d-flex justify-content-end  align-items-center'>
          <div className={classes.paymentsBox}>
            <p className='fw-bold text-end'>Wie möchtest du zahlen?</p>

            <div
              className={`${classes.checkBoxes} ${classes.boxBorder} bg-white py-3   ps-2 pe-1 px-sm-3 small`}
            >
              {/* <div className='d-flex text-small'><MDBBadge className='py-2 mb-2 me-2'>Neu!</MDBBadge>Ab jetzt höheres Limit!</div> */}
              <div className={`d-flex align-items-center `}>
                <MDBRadio
                  name='paymentMethod'
                  id='radio1'
                  value={PAYMENT_METHODS.STRIPE_KLARNA}
                  label='Klarna (in 30 Tagen, Ratenkauf, Sofort)'
                  checked={isPaymentMethodRadioChecked(
                    PAYMENT_METHODS.STRIPE_KLARNA
                  )}
                  onChange={handlePaymentMethodChange}
                />
                <div className={`${classes.klarna} ms-1 mb-2`}>
                  <img src={klarna} width='40px' alt='klarna'></img>
                </div>
              </div>

              {selectedPaymentMethod === PAYMENT_METHODS.STRIPE_KLARNA &&
                isOpenKlarnaOrdersLimitExceeded && (
                  <div
                    className={`px-4 py-3 mb-2 mt-2 text-muted ${classes.borderText}`}
                  >
                    Dein aktueller Verfügungsrahmen bei Klarna beträgt{' '}
                    <b>
                      {klarnaAvailableOrderLimit.toFixed(2).replace('.', ',')} €
                    </b>
                    . Sobald wir die Zahlung für deine vorherigen Einkäufe von
                    Klarna erhalten haben (in der Regel innerhalb von 3
                    Werktagen), steht dir wieder mehr Geld für deine Einkäufe
                    zur Verfügung.
                  </div>
                )}
            </div>
            <div
              className={`${classes.checkBoxes} ${classes.boxBorder} bg-white py-3  ps-2 pe-1 px-sm-3 small`}
            >
              <div className={`d-flex align-items-center`}>
                <MDBRadio
                  name='paymentMethod'
                  id='radio2'
                  value={PAYMENT_METHODS.STRIPE_PAYPAL}
                  label='PayPal'
                  checked={isPaymentMethodRadioChecked(
                    PAYMENT_METHODS.STRIPE_PAYPAL
                  )}
                  onChange={handlePaymentMethodChange}
                />
                <div className={`${classes.paypal} ms-1 mb-2`}>
                  <img
                    src={paypal}
                    width='70px'
                    alt='paypal'
                    className='my-auto'
                  ></img>
                </div>
              </div>
              {/* <p className='text-muted ms-4 my-2'>
                {' '}
                Sicher und direkt mit PayPal bezahlen. Lieferung erfolgt erst
                nach Zahlung.
              </p> */}
              {selectedPaymentMethod === PAYMENT_METHODS.STRIPE_PAYPAL &&
                isOpenPayPalOrdersLimitExceeded && (
                  <div
                    className={`px-4 py-3 mb-2 mt-2 text-muted ${classes.borderText}`}
                  >
                    Dein aktueller Verfügungsrahmen bei PayPal beträgt{' '}
                    <b>
                      {payPalAvailableOrderLimit.toFixed(2).replace('.', ',')} €
                    </b>
                    . Sobald wir die Zahlung für deine vorherigen Einkäufe von
                    PayPal erhalten haben (in der Regel innerhalb von 3
                    Werktagen), steht dir wieder mehr Geld für deine Einkäufe
                    zur Verfügung.
                  </div>
                )}
            </div>
            <div
              className={`${classes.checkBoxes} ${classes.boxBorder} bg-white py-3 ps-2 pe-1 px-sm-3 small`}
            >
              <div className={`d-flex align-items-center`}>
                <MDBRadio
                  name='paymentMethod'
                  id='radio3'
                  value={PAYMENT_METHODS.STRIPE_CARDS}
                  label='Kreditkarte'
                  checked={isPaymentMethodRadioChecked(
                    PAYMENT_METHODS.STRIPE_CARDS
                  )}
                  onChange={handlePaymentMethodChange}
                />
                <div className={`${classes.paypal} ms-1 mb-2`}>
                  <img
                    src={cardPay}
                    width='30px'
                    alt='cards'
                    className='my-auto'
                  ></img>
                </div>
              </div>
              {selectedPaymentMethod === PAYMENT_METHODS.STRIPE_CARDS &&
                isOpenStripeCardsOrdersLimitExceeded && (
                  <div
                    className={`px-4 py-3 mb-2 mt-2 text-muted ${classes.borderText}`}
                  >
                    Dein aktueller Verfügungsrahmen bei Kreditkarte beträgt{' '}
                    <b>
                      {stripeCardsAvailableOrderLimit
                        .toFixed(2)
                        .replace('.', ',')}{' '}
                      €
                    </b>
                    . Sobald wir die Zahlung für deine vorherigen Einkäufe von
                    Kreditkarte erhalten haben (in der Regel innerhalb von 3
                    Werktagen), steht dir wieder mehr Geld für deine Einkäufe
                    zur Verfügung.
                  </div>
                )}
            </div>
            {/* {isApple() && (
              <div
                className={`${classes.checkBoxes} ${classes.boxBorder} bg-white py-3  ps-2 pe-1 px-sm-3 small`}
              >
                <div className={`d-flex align-items-center`}>
                  <MDBRadio
                    name='paymentMethod'
                    id='radio4'
                    value={PAYMENT_METHODS.STRIPE_APPLE_PAY}
                    label='Apple Pay'
                    checked={isPaymentMethodRadioChecked(
                      PAYMENT_METHODS.STRIPE_APPLE_PAY
                    )}
                    onChange={handlePaymentMethodChange}
                  />
                  <div className={` ms-2`}>
                    <img
                      src={applePay}
                      width='40px'
                      alt='cards'
                      className='my-auto'
                    ></img>
                  </div>
                </div>
                {selectedPaymentMethod === PAYMENT_METHODS.STRIPE_APPLE_PAY &&
                  isOpenStripeApplePayOrdersLimitExceeded && (
                    <div
                      className={`px-4 py-3 mb-2 mt-2 text-muted ${classes.borderText}`}
                    >
                      Dein aktueller Verfügungsrahmen bei Apple Pay beträgt{' '}
                      <b>
                        {stripeApplePayAvailableOrderLimit
                          .toFixed(2)
                          .replace('.', ',')}{' '}
                        €
                      </b>
                      . Sobald wir die Zahlung für deine vorherigen Einkäufe von
                      Apple Pay erhalten haben (in der Regel innerhalb von 3
                      Werktagen), steht dir wieder mehr Geld für deine Einkäufe
                      zur Verfügung.
                    </div>
                  )}
              </div>
            )}
            {!isApple() && (
              <div
                className={`${classes.checkBoxes} ${classes.boxBorder} bg-white py-3 ps-2 pe-1 px-sm-3 small`}
              >
                <div className={`d-flex align-items-center`}>
                  <MDBRadio
                    name='paymentMethod'
                    id='radio5'
                    value={PAYMENT_METHODS.STRIPE_GOOGLE_PAY}
                    label='Google Pay'
                    checked={isPaymentMethodRadioChecked(
                      PAYMENT_METHODS.STRIPE_GOOGLE_PAY
                    )}
                    onChange={handlePaymentMethodChange}
                  />
                  <div className={`${classes.paypal} mb-1`}>
                    <img
                      src={googlePay}
                      width='55px'
                      alt='cards'
                      className='my-auto'
                    ></img>
                  </div>
                </div>
                {selectedPaymentMethod === PAYMENT_METHODS.STRIPE_GOOGLE_PAY &&
                  isOpenStripeGooglePayOrdersLimitExceeded && (
                    <div
                      className={`px-4 py-3 mb-2 mt-2 text-muted ${classes.borderText}`}
                    >
                      Dein aktueller Verfügungsrahmen bei Google Pay beträgt{' '}
                      <b>
                        {stripeGooglePayAvailableOrderLimit
                          .toFixed(2)
                          .replace('.', ',')}{' '}
                        €
                      </b>
                      . Sobald wir die Zahlung für deine vorherigen Einkäufe von
                      Google Pay erhalten haben (in der Regel innerhalb von 3
                      Werktagen), steht dir wieder mehr Geld für deine Einkäufe
                      zur Verfügung.
                    </div>
                  )}
              </div>
            )} */}
            <div
              className={`${classes.checkBoxes} ${classes.boxBorder}  bg-white py-3  ps-2 pe-1 px-sm-3 small`}
            >
              <div className={`d-flex align-items-center`}>
                <MDBRadio
                  name='paymentMethod'
                  id='radio6'
                  value={PAYMENT_METHODS.STRIPE_REVOLUT_PAY}
                  label='Revolut Pay'
                  checked={isPaymentMethodRadioChecked(
                    PAYMENT_METHODS.STRIPE_REVOLUT_PAY
                  )}
                  onChange={handlePaymentMethodChange}
                />
                <div className={`mb-1`}>
                  <img
                    src={revolutPay}
                    width='65px'
                    alt='cards'
                    className='my-auto'
                  ></img>
                </div>
              </div>
              {selectedPaymentMethod === PAYMENT_METHODS.STRIPE_REVOLUT_PAY &&
                isOpenStripeRevolutPayOrdersLimitExceeded && (
                  <div
                    className={`px-4 py-3 mb-2 mt text-muted ${classes.borderText}`}
                  >
                    Dein aktueller Verfügungsrahmen bei Revolut Pay beträgt{' '}
                    <b>
                      {stripeRevolutPayAvailableOrderLimit
                        .toFixed(2)
                        .replace('.', ',')}{' '}
                      €
                    </b>
                    . Sobald wir die Zahlung für deine vorherigen Einkäufe von
                    Revolut Pay erhalten haben (in der Regel innerhalb von 3
                    Werktagen), steht dir wieder mehr Geld für deine Einkäufe
                    zur Verfügung.
                  </div>
                )}
            </div>
            <div
              className={
                isPaymentMethodDisabled
                  ? `${classes.checkBoxes} ${classes.boxDown} ${classes.deactivatedPayment} bg-white py-3  px-2 px-sm-3 small mb-4`
                  : `${classes.checkBoxes} ${classes.boxDown} bg-white py-3  px-2 px-sm-3 small mb-4`
              }
            >
              {/* {isPaymentMethodDisabled && (
                <div className='d-flex'>
                  <MDBBadge
                    className={`${classes.badgeText} ${classes.infoColor} py-1  px-2 mb-2 me-2 fw-normal text-normal`}
                  >
                    Nur für Bestandskunden
                    <MDBTooltip
                      title='Diese Option wird aktiviert, nachdem du fünf erfolgreiche Bestellungen aufgegeben hast, die mit anderen Zahlungsmethoden wie z.B. Klarna oder Paypal bezahlt wurden.'
                      tag='span'
                    >
                    <FontAwesomeIcon icon={faCircleInfo} className='ms-2' />
                    </MDBTooltip>
                  </MDBBadge>
                </div>
              )} */}
              <div className='d-flex align-items-center'>
                <MDBRadio
                  name='paymentMethod'
                  id='radio7'
                  value={PAYMENT_METHODS.KREDU_BNPL}
                  label='In 30 Tagen bezahlen'
                  disabled={isPaymentMethodDisabled}
                  checked={isPaymentMethodRadioChecked(
                    PAYMENT_METHODS.KREDU_BNPL
                  )}
                  onChange={handlePaymentMethodChange}
                />
                <div className={`ms-2 mb-2`}>
                  <img src={kredu} width='50px' alt='kredu'></img>
                </div>
              </div>
              <p className='text-muted small ms-4 my-2'>
                Du erhältst nach der Bestellung eine Rechnung und die Gutscheine
                per E-Mail. <br />{' '}
                <span className={classes.smallInfo}>Bonität vorausgesetzt</span>
              </p>
              {selectedPaymentMethod === PAYMENT_METHODS.KREDU_BNPL &&
                isOpenKreduBnplOrdersLimitExceeded && (
                  <div
                    className={`px-4 py-3 mb-2 mt-2 text-muted ${classes.borderText}`}
                  >
                    Du hast dein maximales Limit für diese Zahlungsmethode
                    erreicht.
                    <br />
                    Dein Limit beträgt derzeit{' '}
                    <b>
                      {kreduBnplAvailableOrderLimit
                        .toFixed(2)
                        .replace('.', ',')}{' '}
                      €{' '}
                    </b>
                    und wird nach mehreren erfolgreichen Bestellungen erhöht.{' '}
                    {kreduBnplAvailableOrderLimit === 0.0
                      ? ''
                      : 'Reduziere die Artikel in deinem Warenkorb, um Kauf auf Rechnung als Zahlungsmethode zu verwenden.'}
                  </div>
                )}
              {selectedPaymentMethod === PAYMENT_METHODS.KREDU_BNPL &&
                isPaysafecardLimitExceeded &&
                !isOpenKreduBnplOrdersLimitExceeded &&
                !hasOpenInvoices && (
                  <div
                    className={`px-4 py-3 mb-2 text-muted ${classes.borderText}`}
                  >
                    Aufgrund der hohen Nachfrage ist es vorübergehend nur
                    möglich, Paysafecards im Wert von maximal{' '}
                    <b>{paysafecardLimit.toFixed(2).replace('.', ',')} € </b>
                    auf Rechnung zu bestellen.
                    <br />
                    Im Moment hast du noch{' '}
                    <b>
                      {paysafecardAvailableOrderLimit
                        .toFixed(2)
                        .replace('.', ',')}{' '}
                      €{' '}
                    </b>
                    deines Limits übrig. Bitte reduziere die Anzahl der
                    Paysafecards in deinem Warenkorb, bezahle offene Rechnungen
                    oder verwende eine andere Zahlungsmethode mit höheren
                    Limits, z. B. <b>Klarna oder PayPal</b>, um mehr bestellen
                    zu können.
                  </div>
                )}
            </div>
            {isSubmitError && <FormError />}
            {hasOpenInvoices && (
              <FormError
                errors={[
                  'Du kannst aufgrund unbezahlter Rechnungen keine weiteren Einkäufe tätigen. Bitte bezahle die fälligen Rechnungen, um wieder einkaufen zu können.',
                ]}
              />
            )}
            <MDBBtn
              className={`btn-yellow btn w-100 mt-2 ${
                totalAmount === 0 ||
                (selectedPaymentMethod === PAYMENT_METHODS.KREDU_BNPL &&
                  isOpenKreduBnplOrdersLimitExceeded) ||
                hasOpenInvoices |
                  (selectedPaymentMethod === PAYMENT_METHODS.KREDU_BNPL &&
                    isPaysafecardLimitExceeded) ||
                (selectedPaymentMethod === PAYMENT_METHODS.STRIPE_KLARNA &&
                  isOpenKlarnaOrdersLimitExceeded) ||
                (selectedPaymentMethod === PAYMENT_METHODS.STRIPE_PAYPAL &&
                  isOpenPayPalOrdersLimitExceeded)
                  ? 'disabled'
                  : ''
              }`}
              onClick={handleCartSubmit}
            >
              {isSubmitLoading && (
                <MDBSpinner
                  size='sm'
                  role='status'
                  tag='span'
                  className={`me-2`}
                />
              )}
              Weiter
            </MDBBtn>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cart
